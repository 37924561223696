body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #f9f9f9;
}

a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #0056b3;
}

.container {
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
}


/* Header */

.header {
  background-color: #343a40;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  flex-direction: row;
}


.sticky {
  position: sticky;
  top: 0;
  /* width: 100%; */
}

header .logo {
  display: flex;
  align-items: center;
}

header .logo img {
  height: 50px;
  margin-right: 16px;
}

header .logo h1 {
  color: #fff;
  margin: 0;
  font-size: 20px;
}

.header nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: row;
  align-items: center;
}

.header nav ul li {
  padding: 16px;
}

.nav-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.nav-button:hover {
  background-color: #0056b3;
}

.home-header{
  cursor: pointer;
  margin-right: 20px;
}

.nav-button:hover {
  background-color: #0056b3; 
}

.sign-in {
  background-color: transparent;
  border: 0;
  color: #fff;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sign-in:hover {
  background-color: #fff;
  color: #343a40;
}

.hero {
  background-image: url("/public/Ocean.jpg");
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero button {
  background-color: #007bff;
  border: none;
  color: #fff;
  padding: 1rem 2rem;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

/* Features */

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px;
  background-color: #fff;
}

.features .feature-item {
  flex-basis: 60%;
  text-align: center;
  padding: 16px;
  box-sizing: border-box;
}

.features .feature-item h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 32px;
}

.features .feature-item p {
  color: #666;
  line-height: 1.5;
}

footer {
  background-color:#343a40;
  padding: 20px 0;
  text-align: center;
  color: #fff;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-content img {
  width: 80PX; /* Adjust size as needed */
  height: auto;
  margin-bottom: 10px;
}

.footer-content h3 {
  margin: 5px 0;
}

.footer-content p {
  margin: 5px 0;
}


::-webkit-scrollbar {
  width: 3px; 
}

::-webkit-scrollbar-track {
  background: #343a40
}

::-webkit-scrollbar-thumb {
  background: #fff; 
}