body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }

  .login-page-container{
    background-image: url(/public/Hotel.jpg);
    background-size: cover;
    height: 100vh;
    position: fixed;
    top: 0%;
    width: 100%;
  }
  
  .login-container {
    background-color: #fff;
    max-width: 500px;
    margin: 100px auto;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    align-items: center;
  }

  .login-logo{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .logo-align img{
    height: 50px;
    text-align: center;
    align-items: center;
    
  }

  h2 {
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 20px;
    margin: 10px;
    justify-content: center;
    align-items: center;
  }
  
  label {
    /* display: block; */
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: auto;
  }
  
  button {
    width: 50%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
  }

  .button-login{
    text-align: center;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  